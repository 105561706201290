import axiosIns from '@/libs/axios';

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    getAllForms(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get('getAllForms', { params: queryParams })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    getFormData(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get('getFormData', { params: queryParams })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    getFormById(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get(`getFormById/${id}`)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    formFileDownload(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('formFileDownload', { id: id }, { responseType: 'blob' })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    junkForm(ctx, projectId) {
      return new Promise((resolve, reject) => {
        axiosIns
          .put(`junkForm/${projectId}`)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    completeForm(ctx, projectId) {
      return new Promise((resolve, reject) => {
        axiosIns
          .put(`completeForm/${projectId}`)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    completeProcessing(ctx, projectId) {
      return new Promise((resolve, reject) => {
        axiosIns
          .put(`completeProcessing/${projectId}`)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    getFormQuoteById(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get(`getFormQuoteById/${id}`)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    saveFormQuote(ctx, quoteData) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('saveFormQuote', quoteData)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    selectedJunkForms(ctx, ids) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('selectedJunkForms', ids)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    saveForm(ctx, quoteData) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('saveForm', quoteData)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    saveFormNoMail(ctx, quoteData) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('saveFormNoMail', quoteData)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    

    uploadFormFile(ctx, quoteData) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('uploadFormFile', quoteData)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    uploadPartImage(ctx, quoteData) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('uploadPartImage', quoteData)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },


    

    updateForm(ctx, quoteData) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('updateForm', quoteData)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    duplicateForm(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('duplicateForm', queryParams)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    deleteCertificaFile(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('deleteCertificaFile', queryParams)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    changeFormType(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('changeFormType', queryParams)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    sendQuote(ctx, quoteData) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('sendQuote', quoteData)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    downloadQuote(ctx, quoteData) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('downloadQuote', quoteData, { responseType: 'blob' })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    customerStatus(ctx, quoteData) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('customerStatus', quoteData)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    getDepLabData(ctx) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get('getDepLabData')
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    downloadEditedQuote(ctx, quoteData) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('downloadEditedQuote', quoteData, { responseType: 'blob' })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    getTimelines(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get(`getTimelines/${id}`)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    saveProjectNote(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('saveProjectNote', data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    updateProjectNote(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('updateProjectNote', data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    getProjectNotes(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get('getProjectNotes', { params: data })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    sendMail(ctx, quoteData) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('sendMail', quoteData)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    updateMaterialsCompleted(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('updateMaterialsCompleted', data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    updateMaterialsNotes(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('updateMaterialsNotes', data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    updateToolingCompleted(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('updateToolingCompleted', data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    updateToolingNotes(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('updateToolingNotes', data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    updateOutsideCompleted(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('updateOutsideCompleted', data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    updateOutsideNotes(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('updateOutsideNotes', data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    createShopTicket(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('createShopTicket', { id: id }, { responseType: 'blob' })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    getUsersDropdown(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get('getUsersDropdown', data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    
    updateAssigneeList(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('updateAssigneeList', data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },



    saveCosts(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('saveCosts', data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    
    saveMaterial(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('saveMaterial', data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    
    saveProjectFolder(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('saveProjectFolder', data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    saveProjectFile(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('saveProjectFile', data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    
    updateProjectFile(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .put('updateProjectFile', data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    updateProjectFolder(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .put('updateProjectFolder', data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },


    
    
  },
};
