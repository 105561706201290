<template>
  <b-overlay :show="loading" rounded="sm" no-fade>
    <div class="new-note" v-if="!isEdit">
      <quill-editor id="quil-content-new-note" style="background-color: white;" v-model="tempNewNote" :options="noteOption" @focus="addNewNote" />

      <div id="note-toolbar" class="d-flex justify-content-end">
        <button class="ql-bold"></button>
        <button class="ql-underline"></button>

        <!-- <select class="ql-color">
          <option value="red">Red</option>
          <option value="green">Green</option>
          <option value="blue">Blue</option>
         </select> -->

        <button class="ql-background" value="#fdff32"></button>

        <button class="ql-list" value="ordered"></button>
        <button class="ql-list" value="bullet"></button>
        <button class="ql-link"></button>

        <!-- <label class="toggle thumbs-up-down">
          <input type="checkbox" v-model="isImportant" class="toggle-checkbox" />
          <div class="toggle-btn" v-if="isImportant" v-b-tooltip.hover.top="'Important'"></div>
          <div class="toggle-btn" v-else v-b-tooltip.hover.top="'Not Important'"></div>
        </label>

        <label> -->
        <label>
          <div class="custom-checkbox text-center mr-1 ml-1">
            <span class="checkbox-icon" :class="{ checked: isImportant }" @click="isImportant = !isImportant"></span>
          </div>
          <!-- <input type="checkbox" v-model="note.isImportant" class="toggle-checkbox" />
        <div class="toggle-btn" v-if="note.isImportant" v-b-tooltip.hover.top="'Important'"></div>
        <div class="toggle-btn" v-else v-b-tooltip.hover.top="'Unimportant'"></div> -->
        </label>

        <b-button @click="saveProjectNote" variant="primary" style="width: 60px; font-size: smaller; border-radius: 5px;">
          Save
        </b-button>
      </div>
    </div>

    <div class="cardClass mb-2" v-for="(note, index) in notesData" :key="index">
      <div class="note-header">
        <div class="d-flex w-100 flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
          <div>
            <h6>
              <div style="cursor:pointer" v-if="note.isImportant">
                <div class="custom-checkbox text-center mr-1 ml-1">
                  <span class="checkbox-icon" :class="{ checked: note.isImportant }"></span>
                </div>

                <span>{{ note.fullName }}</span>
              </div>
              <div style="cursor:pointer" v-else>
                <span>{{ note.fullName }}</span>
              </div>
            </h6>
          </div>
          <div>
            <small class="text-muted" style="font-size: smaller;" v-b-tooltip.hover.top="formatDateTime(note.created_at)">{{ formatTimeAgo(note.created_at, note.updated_at) }}</small>
          </div>
        </div>
      </div>

      <div class="notes" @click="clickNote(note)" v-if="!note.isEditing">
        <div class="notes-preview">
          <div v-html="note.note || 'No Note'"></div>
        </div>

        <div class="note-footer">
          <!-- <div class="note-date">{{ new Date(note.created_at).toLocaleString() }}</div> -->
        </div>
      </div>

      <div v-else>
        <quill-editor id="quil-content" style="background-color: white;" v-model="tempEditNote" :options="noteOption" />

        <div id="note-toolbar" class="d-flex justify-content-end">
          <button class="ql-bold"></button>
          <button class="ql-underline"></button>
          <button class="ql-background" value="#fdff32"></button>
          <button class="ql-list" value="ordered"></button>
          <button class="ql-list" value="bullet"></button>
          <button class="ql-link"></button>

          <label>
            <div class="custom-checkbox text-center mr-1 ml-1">
              <span class="checkbox-icon" :class="{ checked: note.isImportant }" @click="note.isImportant = !note.isImportant"></span>
            </div>
            <!-- <input type="checkbox" v-model="note.isImportant" class="toggle-checkbox" />
            <div class="toggle-btn" v-if="note.isImportant" v-b-tooltip.hover.top="'Important'"></div>
            <div class="toggle-btn" v-else v-b-tooltip.hover.top="'Unimportant'"></div> -->
          </label>

          <b-button @click="updateProjectNote(note)" variant="primary" style="width: 60px; font-size: smaller; border-radius: 5px;">
            Update
          </b-button>

          <b-button @click="cancelNoteEdit(note)" variant="danger" class="ml-25" style="width: 60px; font-size: smaller; border-radius: 5px;">
            Cancel
          </b-button>
        </div>
      </div>
    </div>
  </b-overlay>
</template>

<script>
import store from '@/store';
import router from '@/router';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import { BBreadcrumb, BOverlay } from 'bootstrap-vue';
import { quillEditor } from 'vue-quill-editor';
import { formatTimeAgo, formatDateTime } from './helpers';
import 'quill/dist/quill.core.css'; // Quill stil dosyaları

export default {
  components: {
    BOverlay,
    quillEditor,
    BBreadcrumb,
  },

  props: {
    projectId: {
      type: Number,
    },
    projectComments: {
      type: Array,
    },
  },

  data() {
    const noteOption = {
      modules: {
        toolbar: '#note-toolbar',
      },

      placeholder: 'What is this note about?',
    };

    return {
      noteOption,
      loading: false,
      notesData: [],
      isEdit: false,
      tempEditNote: null,
      tempNewNote: null,
      isImportant: false,
    };
  },

  created() {
    if (this.projectComments == null) {
      this.fetchNotes();
    } else {
      this.notesData = this.projectComments;
    }
  },

  methods: {
    formatDateTime,
    formatTimeAgo,

    addNewNote() {
      // Yeni not ekleme işlemi burada gerçekleştirilir
    },

    fetchNotes() {
      this.loading = true;
      store
        .dispatch('project/getProjectNotes', { projectId: this.projectId })
        .then((res) => {
          this.notesData = res.data;

          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    clickNote(val) {
      this.notesData.forEach((el) => {
        el.isEditing = false;
      });
      this.isEdit = true;
      val.isEditing = true;
      this.tempEditNote = val.note;
    },

    updateProjectNote(val) {
      this.loading = true;

      store
        .dispatch('project/updateProjectNote', {
          id: val.id,
          projectId: this.projectId,
          note: this.tempEditNote,
          isImportant: val.isImportant,
        })
        .then((res) => {
          this.loading = false;
          this.isEdit = false;
          val.isEditing = false;
          val.note = this.tempEditNote;

          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Successful',
              text: '✔️ Project note has been updated',
              icon: 'ThumbsUpIcon',
              variant: 'success',
            },
          });
        })
        .catch((error) => {
          this.loading = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'An error occurred',
              text: 'Please try again later or contact support.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          });
        });
    },

    saveProjectNote() {
      this.loading = true;

      var tempData = {
        projectId: this.projectId,
        note: this.tempNewNote,
        isImportant: this.isImportant,
      };

      if (this.tempNewNote != null) {
        store
          .dispatch('project/saveProjectNote', tempData)
          .then((res) => {
            this.loading = false;
            this.tempNewNote = null;
            this.notesData.unshift(res.data);
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Successful',
                text: '✔️ Project Note has been added',
                icon: 'ThumbsUpIcon',
                variant: 'success',
              },
            });
          })
          .catch((error) => {
            this.loading = false;
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'An error occurred',
                text: 'Please try again later or contact support.',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            });
          });
      }
    },

    cancelNoteEdit(val) {
      val.isEditing = false;
      this.tempEditNote = val.note;
      this.isEdit = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.cardClass {
  padding: 1.25rem;
}

.new-note {
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 10px;
  padding-bottom: 10px;
  border: solid 1px transparent;
  transition: border 0.3s;
  border-radius: 5px;
}

.notes {
  transition: border 0.3s;
  padding: 2px;
  border: 1px solid transparent;
}

.notes:hover {
  background-color: #f9f8f8;
  border-radius: 5px;
  cursor: pointer;
  padding: 2px;
  border-color: #ccc;
}

.note-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  // margin-bottom: 10px;
}

.note-user {
  font-weight: bold;
  width: 100%;
}

.note-footer {
  text-align: right;
}

.note-date {
  font-weight: bold;
}

.notes-preview {
  padding-left: 30px;
  padding-top: 10px;
  padding-bottom: 10px;
  border: solid 1px transparent;
  transition: border 0.3s;
  border-radius: 5px;
}

.cardClass + .cardClass {
  border-top: 1px solid #ddd;
  padding-top: 20px;
}

#quil-content ::v-deep {
  > .ql-container {
    border-bottom: 0;
    border-top-right-radius: 0rem;
    border-top-left-radius: 0rem;
    min-height: 50px;
  }

  + #note-toolbar {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}

#quil-content-new-note ::v-deep {
  > .ql-container {
    border-bottom: 0;
    border-top-right-radius: 0rem;
    border-top-left-radius: 0rem;
    min-height: 50px;
  }

  + #note-toolbar {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}

.toggle {
  cursor: pointer;
}

.toggle-checkbox {
  position: absolute;
  visibility: hidden;
}

.thumbs-up-down .toggle-btn::after {
  font-size: 1.5rem;
  content: '\2713';
  margin-left: 1rem;
  margin-right: 1rem;
  color: #000;
}

.thumbs-up-down .toggle-checkbox:checked + .toggle-btn::after {
  font-size: 1.5rem;
  content: '\2713';
  background-color: #198754;
  margin-left: 1rem;
  margin-right: 1rem;
  color: #fff;
}

.custom-checkbox {
  position: relative;
}

/* Style the custom checkbox container */
.custom-checkbox .checkbox-icon {
  width: 25px;
  height: 25px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
/* Style the unchecked state of the checkbox icon */
.custom-checkbox .checkbox-icon:not(.checked)::before {
  content: '\2605'; /* Unicode checkmark character */
  font-size: 25px;
  color: #ccc; /* Gray color for the tick when unchecked */
}

/* Style the checked state of the checkbox icon */
.custom-checkbox .checkbox-icon.checked {
  background-color: #fff; /* Green background when checked */
  color: #ffc400cc;
}

/* Style the tick icon (a simple checkmark) when checked */
.custom-checkbox .checkbox-icon.checked::before {
  content: '\2605'; /* Unicode checkmark character */
  font-size: 25px;
  color: #ffc400cc;
}
</style>
