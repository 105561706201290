// helpers.js

export function formatTimeAgo(dateTimeString, updatedDateTimeString) {
  const now = new Date();
  const date = new Date(dateTimeString);
  const updatedDate = new Date(updatedDateTimeString);
  const diffInMilliseconds = now - date;
  const diffInSeconds = Math.floor(diffInMilliseconds / 1000);
  const diffInMinutes = Math.floor(diffInSeconds / 60);
  const diffInHours = Math.floor(diffInMinutes / 60);
  const diffInDays = Math.floor(diffInHours / 24);

  if (diffInSeconds < 15) {
    return `Just now`;
  } else if (diffInSeconds >= 15 && diffInSeconds < 60) {
    const edited = date.getTime() !== updatedDate.getTime() ? ' (Edited)' : '';
    return `${diffInSeconds} second${diffInSeconds > 1 ? 's' : ''} ago${edited}`;
  } else if (diffInMinutes < 60) {
    const edited = date.getTime() !== updatedDate.getTime() ? ' (Edited)' : '';
    return `${diffInMinutes} minute${diffInMinutes > 1 ? 's' : ''} ago${edited}`;
  } else if (diffInHours < 24) {
    const edited = date.getTime() !== updatedDate.getTime() ? ' (Edited)' : '';
    return `${diffInHours} hour${diffInHours > 1 ? 's' : ''} ago${edited}`;
  } else {
    const edited = date.getTime() !== updatedDate.getTime() ? ' (Edited)' : '';
    return `${diffInDays} day${diffInDays > 1 ? 's' : ''} ago${edited}`;
  }
}

export function formatDateTime(dateTimeString) {
  const dateTime = new Date(dateTimeString);
  const year = dateTime.getFullYear();
  const month = String(dateTime.getMonth() + 1).padStart(2, '0');
  const day = String(dateTime.getDate()).padStart(2, '0');
  const hour = String(dateTime.getHours()).padStart(2, '0');
  const minute = String(dateTime.getMinutes()).padStart(2, '0');

  return `${year}-${month}-${day} ${hour}:${minute}`;
}

export function getAvatarText(value) {
  if (value) {
    const words = value.split(' ');
    const initials = words.map((word) => word.charAt(0).toUpperCase());
    return initials.join('');
  } else {
    return 'NA';
  }
}

export function hoursFormat(val) {
  var totalMinutes = Math.floor(val / 60);

  var hours = Math.floor(totalMinutes / 60);
  var minutes = totalMinutes % 60;

  return hours + ' Hours, ' + minutes + ' Minutes';
}

export function getTodayDate() {
  const today = new Date();
  const day = String(today.getDate()).padStart(2, '0');
  const month = String(today.getMonth() + 1).padStart(2, '0'); // Note: January is 0!
  const year = today.getFullYear();
  const todayDate = `${year}-${month}-${day}`;
  return todayDate;
}

export function formatDate(value) {
  if (!value) return value;
  const date = new Date(value);
  const day = date
    .getDate()
    .toString()
    .padStart(2, '0');
  const month = new Intl.DateTimeFormat('en-US', { month: 'short' }).format(date); // Kısa ay adını alır
  const year = date
    .getFullYear()
    .toString()
    .slice(-2);
  return `${year}-${month}-${day}`;
}
