<template>
  <b-sidebar
    id="sidebar-task-handler"
    @change="
      (val) => {
        $emit('update:is-history-sidebar-active', val);
      }
    "
    sidebar-class="sidebar-lg"
    @shown="openSidebar()"
    @hidden="clearSidebarData()"
    :visible="isHistorySidebarActive"
    bg-variant="white"
    shadow
    width="600px"
    backdrop
    right
  >
    <div>
      <b-overlay style="overflow-x: hidden;" :show="loading" rounded="sm">
        <b-row v-if="timelineData != null">
          <b-col cols="12">
            <b-card style="background-color: white !important;">
              <span
                @click="activeTab = 1"
                :class="{
                  activeMenu: activeTab === 1,
                  passiveMenu: activeTab !== 1,
                }"
              >
                <i class="fa-solid fa-table-columns mr-25 mb-3 "></i> Timeline
              </span>

              <span
                @click="activeTab = 2"
                :class="{
                  activeMenu: activeTab === 2,
                  passiveMenu: activeTab !== 2,
                }"
              >
                <i class="fa-solid fa-table-columns mr-25 mb-3 "></i> Notes
              </span>

              <span
                @click="activeTab = 3"
                :class="{
                  activeMenu: activeTab === 3,
                  passiveMenu: activeTab !== 3,
                }"
              >
                <i class="fa-solid fa-table-columns mr-25 mb-3 "></i> Materials
              </span>
              <span
                @click="activeTab = 4"
                :class="{
                  activeMenu: activeTab === 4,
                  passiveMenu: activeTab !== 4,
                }"
              >
                <i class="fa-solid fa-table-columns mr-25 mb-3 "></i> Tooling
              </span>
              <span
                @click="activeTab = 5"
                :class="{
                  activeMenu: activeTab === 5,
                  passiveMenu: activeTab !== 5,
                }"
              >
                <i class="fa-solid fa-table-columns mr-25 mb-3 "></i> O. Process
              </span>
              <span
                @click="activeTab = 6"
                :class="{
                  activeMenu: activeTab === 6,
                  passiveMenu: activeTab !== 6,
                }"
              >
                <i class="fa-solid fa-table-columns mr-25 mb-3 "></i> Survey
              </span>

              <span
                @click="activeTab = 7"
                :class="{
                  activeMenu: activeTab === 7,
                  passiveMenu: activeTab !== 7,
                }"
              >
                <i class="fa-solid fa-table-columns mr-25 mb-3 "></i> Files
              </span>

              <div v-if="activeTab == 1">
                <div class="cardClass">
                  <app-timeline>
                    <!-- 12 INVOICES HAVE BEEN PAID -->

                    <app-timeline-item v-for="item in timelineData" :icon="iconTimeline(item.status)" :variant="variantTimeline(item.status)" :key="item.index">
                      <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
                        <h6>{{ item.title }}</h6>
                        <small class="text-muted" style="font-size: smaller;">{{ dateFormatTimeline(item.created_at) }}</small>
                      </div>
                      <p v-if="item.status == 'Created'">Project has been created by {{ item.username }}.</p>
                      <p v-if="item.status == 'QuoteCreated'">Project has been created by {{ item.username }}.</p>
                      <p v-if="item.status == 'Approved'">Quote has been approved by {{ item.username }}.</p>
                      <p v-if="item.status == 'Rejected'">Quote has been rejected by {{ item.username }}.</p>
                      <p v-if="item.status == 'Call'">A call has been requested by {{ item.username }}.</p>
                      <p v-if="item.status == 'Edited'">Project has been edited by {{ item.username }}.</p>
                      <p v-if="item.status == 'QuoteEdited'">Quote has been edited by {{ item.username }}.</p>
                      <p v-if="item.status == 'Canceled'">Project has been cancelled due to no Charge String/PO Number.</p>
                      <p v-if="item.status == 'Completed'">Project has been approved by {{ item.username }}.</p>
                      <p v-if="item.status == 'Processed'">Project has been marked processed by {{ item.username }}.</p>
                      <p v-if="item.status == 'Mailed'">Quote has been mailed by {{ item.username }}.</p>
                      <p v-if="item.status == 'MailSent'">A mail has been sent to customer by {{ item.username }}.</p>
                      <p v-if="item.status == 'Invoiced'">Project has been invoiced.</p>

                      <p v-if="item.status == 'Archived'">Project has been archived by {{ item.username }}.</p>
                      <p v-if="item.status == 'ChargeString'">Customer has provided the Charge String.</p>
                      <p v-if="item.status == 'Survey'">A survey has been filled by the customer.</p>
                      <p v-if="item.status == 'ChargeReminder'">First reminder to customer has been sent for Charge String/PO Number.</p>
                      <p v-if="item.status == 'LastChargeReminder'">Last reminder to customer has been sent for Charge String/PO Number.</p>
                      <p v-if="item.status == 'LastChargeReminder'">Last reminder to customer has been sent for Charge String/PO Number.</p>

                      <p v-if="item.fileLink" style="cursor: pointer;">
                        <b-img :src="require('@/assets/images/pdf.png')" height="auto" width="20" class="mr-1" @click="downloadFile(item.fileLink, item.fileName)" />
                        <span @click="downloadFile(item.fileLink, item.fileName)" class="align-bottom">{{ item.fileName }}</span>
                      </p>

                      <div v-if="item.content != null && item.status == 'MailSent'">
                        <b-list-group class="mt-1">
                          <b-list-group-item class="d-flex justify-content-between align-items-center bg-transparent">
                            <span><strong>Sent to: </strong> {{ item.content.to }}</span>
                          </b-list-group-item>
                          <b-list-group-item class="d-flex justify-content-between align-items-center bg-transparent">
                            <span><strong>Subject: </strong> {{ item.content['subject'] }}</span>
                          </b-list-group-item>
                          <b-list-group-item class="d-flex justify-content-between align-items-center bg-transparent">
                            <span v-html="item.content['content']"> </span>
                          </b-list-group-item>
                        </b-list-group>
                      </div>

                      <div v-if="item.content != null && item.status == 'ContactEdit'">
                          <b-list-group-item class="d-flex justify-content-between align-items-center bg-transparent">
                            <span>Contact details have been updated by {{ item.username }}. Previous details: </span>
                          </b-list-group-item>
                        <b-list-group class="mt-1">
                          <b-list-group-item class="d-flex justify-content-between align-items-center bg-transparent">
                            <span><strong>Contact: </strong> {{ item.content['contact'] }}</span>
                          </b-list-group-item>
                          <b-list-group-item class="d-flex justify-content-between align-items-center bg-transparent">
                            <span><strong>Email: </strong> {{ item.content['contactEmail'] }}</span>
                          </b-list-group-item>
                          <b-list-group-item class="d-flex justify-content-between align-items-center bg-transparent">
                            <span><strong>Phone: </strong> {{ item.content['contactPhone'] }}</span>
                          </b-list-group-item>
                          <b-list-group-item class="d-flex justify-content-between align-items-center bg-transparent">
                            <span><strong>Department: </strong> {{ item.content['department'] }}</span>
                          </b-list-group-item>
                          <b-list-group-item class="d-flex justify-content-between align-items-center bg-transparent">
                            <span><strong>Lab: </strong> {{ item.content['lab'] }}</span>
                          </b-list-group-item>
                          <b-list-group-item class="d-flex justify-content-between align-items-center bg-transparent">
                            <span><strong>Address: </strong> {{ item.content['companyAddress'] }}</span>
                          </b-list-group-item>
                         
                        </b-list-group>
                      </div>

                      <div v-if="item.content != null && item.status == 'ChargeStringEdit'">
                          <b-list-group-item class="d-flex justify-content-between align-items-center bg-transparent">
                            <span>Charge string has been updated by {{ item.username }}. Previous details: </span>
                          </b-list-group-item>
                        <b-list-group class="mt-1">
                          <b-list-group-item class="d-flex justify-content-between align-items-center bg-transparent">
                            <span><strong>Previous: </strong> {{ item.content['oldChargeString'] }}</span>
                          </b-list-group-item>
                          <b-list-group-item class="d-flex justify-content-between align-items-center bg-transparent">
                            <span><strong>New: </strong> {{ item.content['newChargeString'] }}</span>
                          </b-list-group-item>
                        
                         
                        </b-list-group>
                      </div>




                      <div v-if="item.content != null && item.status == 'Rejected'">
                        <b-list-group class="mt-1">
                          <b-list-group-item class="d-flex justify-content-between align-items-center bg-transparent">
                            <span><strong>Reasons: </strong> {{ item.content['reasons'] }}</span>
                          </b-list-group-item>
                          <b-list-group-item class="d-flex justify-content-between align-items-center bg-transparent">
                            <p style="word-wrap: break-word;">{{ item.content['comment'] ? item.content['comment'] : 'N/A' }}</p>
                          </b-list-group-item>
                        </b-list-group>
                      </div>
                    </app-timeline-item>
                  </app-timeline>
                </div>
              </div>

              <div v-if="activeTab == 2">
                <notes-component :project-id="projectId"></notes-component>
              </div>

              <div v-if="activeTab == 3">


                
                <b-form-group label="Part Name" label-for="companyAddress">
                  <v-select v-model="selectedPart" id="type" :options="partDropdown" @input="partSelected" :clearable="false" />
                </b-form-group>


              

                <b-form-group label="Vendor" label-for="companyAddress">
                  <b-form-input type="text" v-model="materialsVendor" ></b-form-input>
                </b-form-group>
                <b-form-group label="Description" label-for="companyAddress">
                  <b-form-input type="text" v-model="materialsDescription" ></b-form-input>
                </b-form-group>
                <b-form-group label="Cost" label-for="companyAddress">
                  <b-form-input type="number" v-model="materialsCost" ></b-form-input>
                </b-form-group>


        

                <div clas="d-flex no-wrap mb-3 mt-3" v-if="selectedPart">
                  <b-button @click="saveCosts" :variant="success">
                   Save
                  </b-button>
                </div>


                <b-form-group label="Notes" label-for="companyAddress">
                  <b-form-textarea type="text" v-model="materialsNotes" @change="updateMaterialsNotes"></b-form-textarea>
                </b-form-group>


                <div class="d-flex no-wrap">
                  <b-form-checkbox v-model="materialsCompleted" @change="updateMaterialsCompleted" name="materialsCompleted" class="mr-2">Materials has been completed.</b-form-checkbox>
                </div>

              </div>

              <div v-if="activeTab == 4">

        

                <b-form-group label="Part Name" label-for="companyAddress">
                  <v-select v-model="selectedPart" id="type" :options="partDropdown" @input="partSelected" :clearable="false" />
                </b-form-group>

                <b-form-group label="Vendor" label-for="companyAddress">
                  <b-form-input type="text" v-model="toolingVendor" ></b-form-input>
                </b-form-group>
                <b-form-group label="Description" label-for="companyAddress">
                  <b-form-input type="text" v-model="toolingDescription"></b-form-input>
                </b-form-group>
                <b-form-group label="Cost" label-for="companyAddress">
                  <b-form-input type="number" v-model="toolingCost" ></b-form-input>
                </b-form-group>

     

                <div clas="d-flex no-wrap mb-3 mt-3" v-if="selectedPart">
                  <b-button @click="saveCosts" :variant="success">
                   Save
                  </b-button>
                </div>

                
                <b-form-group label="Notes" label-for="companyAddress">
                  <b-form-textarea type="text" v-model="toolingNotes" @change="updateToolingNotes"></b-form-textarea>
                </b-form-group>


                <div class="d-flex no-wrap">
                  <b-form-checkbox v-model="toolingCompleted" name="toolingCompleted" class="mr-2" @change="updateToolingCompleted">Tooling has been completed.</b-form-checkbox>
                </div>


              </div>

              <div v-if="activeTab == 5">

                <b-form-group label="Part Name" label-for="companyAddress">
                  <v-select v-model="selectedPart" id="type" :options="partDropdown" @input="partSelected" :clearable="false" />
                </b-form-group>

             
          

                <b-form-group label="Vendor" label-for="companyAddress">
                  <b-form-input type="text" v-model="outsideVendor" ></b-form-input>
                </b-form-group>
                <b-form-group label="Description" label-for="companyAddress">
                  <b-form-input type="text" v-model="outsideDescription" ></b-form-input>
                </b-form-group>
                <b-form-group label="Cost" label-for="companyAddress">
                  <b-form-input type="number" v-model="outsideCost" ></b-form-input>
                </b-form-group>

            
                <div clas="d-flex no-wrap mb-3 mt-3" v-if="selectedPart">
                  <b-button @click="saveCosts" :variant="success">
                   Save
                  </b-button>
                </div>

             


                <b-form-group label="Notes" label-for="companyAddress">
                  <b-form-textarea type="text" v-model="outsideNotes" @change="updateOutsideNotes"></b-form-textarea>
                </b-form-group>

                <div class="d-flex no-wrap">
                  <b-form-checkbox v-model="outsideCompleted" name="outsideCompleted" class="mr-2" @change="updateOutsideCompleted">Outside Process has been completed.</b-form-checkbox>
                </div>

              </div>

              <div v-if="activeTab == 6">
                <div>
                  <b-list-group class="mt-1" v-for="i in surveyData" :key="i.index">
                    <b-list-group-item class="d-flex justify-content-between align-items-center bg-transparent">
                      <span>
                        <strong>{{ i.question }} </strong>
                      </span>
                    </b-list-group-item>
                    <b-list-group-item class="d-flex justify-content-between align-items-center bg-transparent">
                      <p style="word-wrap: break-word;">{{ i.response ? i.response : 'N/A' }}</p>
                    </b-list-group-item>
                  </b-list-group>
                </div>
              </div>

              <div v-if="activeTab == 7">
                <div class="cardClass">
                  <div class="subTitle">Files</div>

                  <b-overlay v-if="projectId != null" rounded="sm" no-fade>
                    <div>
                      <div class="d-flex justify-content-end">
                        <b-button-group>
                          <b-button @click="newFolder" size="sm" variant="outline-primary">
                            New Folder
                          </b-button>
                          <b-button @click="uploadFiles" size="sm" variant="outline-primary">
                            Upload Files
                          </b-button>
                        </b-button-group>
                      </div>

                      <div id="breadcrumb-alignment" class="mb-1" v-if="foldersData.length > 0">
                        <div id="menu" class="d-flex flex-column align-items-left">
                          <b-breadcrumb>
                            <b-breadcrumb-item v-for="(item, i) in folderTemp" :key="i" :class="item.active == true ? 'active' : ''" @click="menuClick(item)">
                              <feather-icon icon="HomeIcon" class="mr-50 mb-25" v-if="i == 0" />
                              {{ item.text }}
                            </b-breadcrumb-item>
                          </b-breadcrumb>
                        </div>
                      </div>

                      <div id="breadcrumb-alignment" class="mb-1 no-file-message" v-else>
                        No Files
                      </div>

                      <!-- <div v-if="foldersData.length > 0">
                        <div class="custom-grid">
                          <div v-for="x in foldersData" v-if="x.folderId == folderId" :key="x.type + x.id" class="custom-card">
                            <div @click="itemClick(x)" class="custom-card-content">
                              <img :src="x.type == 'folder' ? '/folder.png' : '/file.png'" :alt="x.type" class="custom-card-image" />
                              <div class="custom-card-footer" v-if="x.type == 'folder'">
                                {{ truncateText(x.folderName, 64) }}
                              </div>
                              <div class="custom-card-footer" v-if="x.type == 'file'">
                                {{ truncateText(x.fileName, 64) }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div> -->

                      <div id="files-section" v-if="foldersData.length > 0">
                        <span  v-for="x in foldersData" v-if="x.folderId == folderId" :key="x.type + x.id" class="custom-card">
                          <div class="custom-card-content d-flex align-items-center">

                              <a style="text-decoration: none;" target="_blank" @click="itemClick(x)">

                                <b-img :src="x.type == 'folder' ? '/folder.png' : '/file.png'" :alt="x.type" height="auto" width="35" class="mr-1" style="float: left;" />
                                <!-- <small>{{ dateFormat(item.created_at) }}</small> -->
                                <div class="d-flex flex-wrap-nowrap mt-1">
                                  <span class="custom-card-footer" v-if="x.type == 'folder'">
                                              {{ truncateText(x.folderName, 64) }}
                                            </span>
                                            <span class="custom-card-footer " v-if="x.type == 'file'">
                                              {{ truncateText(x.fileName, 64) }}
                                            </span>
                                      
                                </div>

                          </a>

                            <b-button  variant="danger" @click="deleteFile(x)" class="btn-icon ml-auto float-right" style="padding: 1% 1%;" size="sm">
                            <feather-icon icon="XIcon" />
                          </b-button>

                                        
                      
                         
                </div>
              

              </span>
          </div>

        


                      <!-- Add New Folder -->
                      <b-modal id="modal-new-folder" @ok="handleFolderOk" @show="resetModal" :no-close-on-backdrop="true" :no-close-on-esc="true" :ok-only="true" cancel-variant="outline-secondary" header-bg-variant="primary" size="sm" ok-title="Save Folder" centered title="Add New Folder">
                        <validation-observer ref="folderRules">
                          <b-form>
                            <b-form-group>
                              <label for="folderName">Folder Name:</label>
                              <validation-provider name="Folder Name" #default="{ errors }" rules="required">
                                <b-form-input id="folderName" type="text" v-model="modalFolderName" placeholder="Folder Name" />
                                <small class="text-danger">{{ errors[0] }}</small>
                              </validation-provider>
                            </b-form-group>
                          </b-form>
                        </validation-observer>
                      </b-modal>

                      <!-- Add New Files -->
                      <b-modal id="modal-new-file" @ok="handleFileOk" @show="resetModal" :no-close-on-backdrop="true" :no-close-on-esc="true" :ok-only="true" cancel-variant="outline-secondary" header-bg-variant="primary" size="sm" ok-title="Save File" centered title="Add New Files">
                        <validation-observer ref="fileRules">
                          <b-form>
                            <b-form-group>
                              <validation-provider name="File" #default="{ errors }" rules="required">
                                <b-form-file type="file" v-model="modalFiles" multiple placeholder="Choose a file or drop it here..." drop-placeholder="Drop file here..." />
                                <small class="text-danger">{{ errors[0] }}</small>
                              </validation-provider>
                            </b-form-group>
                          </b-form>
                        </validation-observer>

                        <b-col v-if="percentCompleted != 0 && percentCompleted != 100" class="mt-2">
                          <b-progress key="info" animated :value="percentCompleted" show-progress variant="dark" class="'progress-bar-info'" />
                        </b-col>
                      </b-modal>

                      <!-- Edit Folder  -->

                      <b-modal id="modal-edit-folder" @ok="handleFolderUpdate" @hidden="resetModal" :no-close-on-backdrop="true" :no-close-on-esc="true" :ok-only="true" cancel-variant="outline-secondary" header-bg-variant="primary" size="sm" ok-title="Update Folder" centered title="Edit Folder">
                        <validation-observer ref="folderRules">
                          <b-form>
                            <b-form-group>
                              <label for="folderName">Folder Name:</label>
                              <validation-provider name="Folder Name" #default="{ errors }" rules="required">
                                <b-form-input id="folderName" type="text" v-model="modalFolderName" placeholder="Folder Name" />
                                <small class="text-danger">{{ errors[0] }}</small>
                              </validation-provider>
                            </b-form-group>
                          </b-form>
                        </validation-observer>
                      </b-modal>

                      <!-- Edit File Name -->
                      <b-modal id="modal-edit-file" @ok="handleFileUpdate" @show="resetModal" :no-close-on-backdrop="true" :no-close-on-esc="true" :ok-only="true" cancel-variant="outline-secondary" header-bg-variant="primary" size="sm" ok-title="Update File" centered title="Edit New Files">
                        <validation-observer ref="fileRules">
                          <b-form>
                            <b-form-group>
                              <validation-provider name="File Name" #default="{ errors }" rules="required">
                                <b-form-input id="fileName" type="text" v-model="modalFileName" placeholder="File Name" />
                                <small class="text-danger">{{ errors[0] }}</small>
                              </validation-provider>
                            </b-form-group>
                          </b-form>
                        </validation-observer>
                      </b-modal>
                    </div>
                  </b-overlay>
                </div>
              </div>
            </b-card>
          </b-col>
        </b-row>
      </b-overlay>
    </div>
  </b-sidebar>
</template>

<script>
import store from '@/store';
import { VBPopover, BSidebar } from 'bootstrap-vue';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import { quillEditor } from 'vue-quill-editor';
import vSelect from 'vue-select';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { BImg, BAvatar, BMedia, BButton, BCollapse, VBToggle, BListGroup, BListGroupItem, BAvatarGroup, BBadge, VBTooltip } from 'bootstrap-vue';
import VueContext from 'vue-context';
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue';
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue';
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue';
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue';
import moment from 'moment';
import NotesComponent from './NotesComponent.vue';
import 'quill/dist/quill.snow.css';
import { required, email } from '@validations';
import axiosIns from '@/libs/axios';

export default {
  components: {
    ToastificationContent,
    quillEditor,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    BSidebar,
    VueContext,
    AppCollapse,
    AppCollapseItem,
    BImg,
    BAvatar,
    BMedia,
    BButton,
    BCollapse,
    VBToggle,
    BListGroup,
    BListGroupItem,
    BAvatarGroup,
    BBadge,
    VBTooltip,
    AppTimeline,
    AppTimelineItem,
    NotesComponent,
    
  },

  directives: {
    'b-popover': VBPopover,
    'b-toggle': VBToggle,
    'b-tooltip': VBTooltip,
  },

  model: {
    prop: 'isHistorySidebarActive',
    event: 'update:is-history-sidebar-active',
  },

  props: {
    isHistorySidebarActive: {
      type: Boolean,
      required: true,
      default: false,
    },
    projectId: {
      type: Number,
      required: true,
    },
    activeTabParameter: {
      type: Number,
      required: false,
      default: 1,
    },
  },

  data() {
    const tempProjectId = parseInt(this.projectId);
    return {
      required, email,
      popoverShow: {},
      selectedUserId: null,
      selectedUserTitle: null,
      initialDescription: null,

      loading: true,
      activeTab: 1,

      timelineData: null,
      surveyData: null,
      materialsCompleted: false,
      toolingCompleted: false,
      outsideCompleted: false,
      materialsNotes: null,
      toolingNotes: null,
      outsideNotes: null,
      partDropdown:[],
      formCosts : [],
      selectedPart:null,
      materialsVendor : null,  
      materialsDescription : null, 
      materialsCost : null,  
     
      toolingVendor : null,  
      toolingDescription : null, 
      toolingCost : null, 
      
      outsideVendor : null, 
      outsideDescription : null,
      outsideCost : null,  


      rightClickData: [],
      fileURL: store.state.app.fileURL,

      folderId: 0,
      modalFolderName: null,
      modalFiles: null,
      modalFile: [],
      percentCompleted: 0,
      directoryPath: '',
      folderTemp: [
        {
          text: 'Main',
          id: 0,
          active: true,
          projectId: tempProjectId,
        },
      ],

      foldersData: [],



      userData: JSON.parse(localStorage.getItem('userData')),
    };
  },

  mounted() {
    this.activeTab = this.activeTabParameter;
    this.getTimelines();
  },
  watch: {
    isHistorySidebarActive: function(newVal, oldVal) {
      if (!newVal) {
        this.getTimelines();
      }
    },

    
    'activeTab'() {
          this.selectedPart = null;
          this.materialsVendor = null;  
          this.materialsDescription = null; 
          this.materialsCost = null;  
        
          this.toolingVendor = null;  
          this.toolingDescription = null; 
          this.toolingCost = null; 
          
          this.outsideVendor = null; 
          this.outsideDescription = null;
          this.outsideCost = null;  
      },


  },

  methods: {
    downloadFile(fileLink, filename) {
      this.loading = true;
      store
        .dispatch('project/downloadEditedQuote', { link: fileLink })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          let fileName = filename;
          link.setAttribute('download', fileName);
          document.body.appendChild(link);
          link.click();
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'An error occurred',
              text: 'Please try again later or contact support.',
              icon: 'AlertCircleIcon',
              variant: 'danger',
            },
          });
        });
    },

    getTimelines() {
      const _id = parseInt(this.projectId);

      store
        .dispatch('project/getTimelines', { id: _id })
        .then((res) => {
          this.timelineData = res.data.timelines;
          this.surveyData = res.data.survey;
          this.materialsCompleted = res.data.materialsCompleted == 1 || res.data.materialsCompleted == true ? true : false;
          this.toolingCompleted = res.data.toolingCompleted == 1 || res.data.toolingCompleted == true ? true : false;
          this.outsideCompleted = res.data.outsideCompleted == 1 || res.data.outsideCompleted == true ? true : false;

          this.materialsNotes = res.data.materialsNotes;
          this.toolingNotes = res.data.toolingNotes;
          this.outsideNotes = res.data.outsideNotes;
          this.partDropdown = res.data.partDropdown;
          this.formCosts = res.data.formCosts;
          this.foldersData = res.data.folders;

          this.loading = false;
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'An error occurred',
              text: 'Please try again later or contact support.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          });
        });
    },

    updateMaterialsCompleted() {
      const _id = parseInt(this.projectId);

      store
        .dispatch('project/updateMaterialsCompleted', { id: _id, isCompleted: this.materialsCompleted })
        .then((res) => {
          this.$emit('materialsCompleted');
          this.loading = false;
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'An error occurred',
              text: 'Please try again later or contact support.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          });
        });
    },
    updateMaterialsNotes() {
      const _id = parseInt(this.projectId);

      store
        .dispatch('project/updateMaterialsNotes', { id: _id, notes: this.materialsNotes })
        .then((res) => {
          this.loading = false;
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'An error occurred',
              text: 'Please try again later or contact support.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          });
        });
    },
    updateToolingCompleted() {
      const _id = parseInt(this.projectId);

      store
        .dispatch('project/updateToolingCompleted', { id: _id, isCompleted: this.toolingCompleted })
        .then((res) => {
          this.$emit('toolingCompleted');

          this.loading = false;
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'An error occurred',
              text: 'Please try again later or contact support.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          });
        });
    },
    updateToolingNotes() {
      const _id = parseInt(this.projectId);

      store
        .dispatch('project/updateToolingNotes', { id: _id, notes: this.toolingNotes })
        .then((res) => {
          this.loading = false;
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'An error occurred',
              text: 'Please try again later or contact support.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          });
        });
    },

    updateOutsideCompleted() {
      const _id = parseInt(this.projectId);

      store
        .dispatch('project/updateOutsideCompleted', { id: _id, isCompleted: this.outsideCompleted })
        .then((res) => {
          this.$emit('outsideCompleted');

          this.loading = false;
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'An error occurred',
              text: 'Please try again later or contact support.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          });
        });
    },
    updateOutsideNotes() {
      const _id = parseInt(this.projectId);

      store
        .dispatch('project/updateOutsideNotes', { id: _id, notes: this.outsideNotes })
        .then((res) => {
          this.loading = false;
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'An error occurred',
              text: 'Please try again later or contact support.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          });
        });
    },


    saveCosts() {
      const _id = parseInt(this.projectId);

      const index = this.partDropdown.findIndex(item => item === this.selectedPart);
      if(this.activeTab==3){
  

          let formData = {
            cost: this.materialsCost,
            vendor: this.materialsVendor,
            description: this.materialsDescription,
            type:'material',
            formIndex:index,
            projectId:_id
          }

          store
        .dispatch('project/saveCosts', { id:_id, cost : formData,type:'material',formIndex:index })
        .then((res) => {
          this.loading = false;

          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Cost Saved',
              text: 'Cost has been successfully saved.',
              icon: 'SuccessIcon',
              variant: 'success',
            },
          });

        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'An error occurred',
              text: 'Please try again later or contact support.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          });
        });
       
      }
      if(this.activeTab==4){
  
          let formData = {
            cost: this.toolingCost,
            vendor: this.toolingVendor,
            description: this.toolingDescription,
            type:'tooling',
            formIndex:index,
            projectId:_id
        }

        store
        .dispatch('project/saveCosts', { id:_id, cost : formData,type:'tooling',formIndex:index })
        .then((res) => {
          this.loading = false;
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'An error occurred',
              text: 'Please try again later or contact support.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          });
        });

      }
      if(this.activeTab==5){
        let formData = {
            cost: this.outsideCost,
            vendor: this.outsideVendor,
            description: this.outsideDescription,
            type:'outside',
            formIndex:index,
            projectId:_id
        }

        store
        .dispatch('project/saveCosts', { id:_id, cost : formData,type:'outside',formIndex:index })
        .then((res) => {
          this.loading = false;
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'An error occurred',
              text: 'Please try again later or contact support.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          });
        });

      }
      

   
    },



    partSelected(){
      this.materialsVendor = null;
          this.materialsDescription = null;
          this.materialsCost = null;
          this.toolingVendor = null;
          this.toolingDescription = null;
          this.toolingCost = null;
          this.outsideVendor =  null;
          this.outsideDescription = null;
          this.outsideCost = null;

      const index = this.partDropdown.findIndex(item => item === this.selectedPart);
      if(this.activeTab==3){
        const costs = this.formCosts.filter((e)=>e.formIndex === index && e.type === 'material')[0] ?? null;
        if(costs){
   
          this.materialsVendor = costs.vendor;
          this.materialsDescription = costs.description;
          this.materialsCost = costs.cost;
        }
       
      }
      if(this.activeTab==4){
        const costs = this.formCosts.filter((e)=>e.formIndex === index && e.type === 'tooling')[0] ?? null;
        if(costs){
          this.toolingVendor = costs.vendor;
          this.toolingDescription = costs.description;
          this.toolingCost = costs.cost;
        }

      }
      if(this.activeTab==5){
        const costs = this.formCosts.filter((e)=>e.formIndex === index && e.type === 'outside')[0] ?? null;
        if(costs){
          this.outsideVendor = costs.vendor;
          this.outsideDescription = costs.description;
          this.outsideCost = costs.cost;
        }

      }
    },

    newFolder() {
      this.$bvModal.show('modal-new-folder');
    },

    uploadFiles(val) {
      this.$bvModal.show('modal-new-file');
    },

    handleClick(event, type, item) {
      if (type == 'folder') {
        this.rightClickData = [
          { icon: 'FileIcon', text: 'Open', data: item },
          { icon: 'EditIcon', text: 'Edit', data: item },
          { icon: 'XIcon', text: 'Delete', data: item },
        ];
        this.$refs.rightClicks.open(event, item);
      } else if (type == 'file') {
        this.rightClickData = [
          { icon: 'EditIcon', text: 'Edit', data: item },
          { icon: 'XIcon', text: 'Delete', data: item },
          { icon: 'SaveIcon', text: 'Save', data: item },
        ];
        this.$refs.rightClicks.open(event, item);
      }
    },

    menuClick(val) {
      this.folderId = val.id;

      if (val.id == 0) {
        this.folderTemp = [
          {
            text: 'Main',
            id: 0,
            active: true,
            companyId: -1,
          },
        ];
      } else {
        var tempFolder = [];
        var finished = false;
        this.folderTemp.forEach((element) => {
          if (finished == false) {
            if (element.id == val.id) {
              tempFolder.push({
                text: element.text,
                id: element.id,
                active: true,
                companyId: element.companyId,
              });
              this.folderTemp = tempFolder;
              finished = true;
            } else {
              tempFolder.push(element);
            }
          }
        });
      }
    },

    optionClicked(val) {
      switch (val.text) {
        case 'Open':
          this.itemClick(val.data);
          break;

        case 'Edit':
          if (val.data.type == 'folder') {
            this.modalFolderName = val.data.folderName;
            this.selectFolderId = val.data.id;

            this.$bvModal.show('modal-edit-folder');
          } else {
            this.modalFileName = val.data.fileName;
            this.selectFileId = val.data.id;

            this.$bvModal.show('modal-edit-file');
          }

          break;

        case 'Save':
          this.loading = true;
          this.itemClick(val.data);
          break;

        case 'Delete':
          if (val.data.type == 'file') {
            this.$bvModal
              .msgBoxConfirm('Are you sure you want to delete this file?', {
                size: 'sm',
                title: 'Confirm Deletion',
                okVariant: 'primary',
                headerBgVariant: 'primary',
                okTitle: 'Yes',
                cancelTitle: 'No',
                cancelVariant: 'outline-secondary',
                hideHeaderClose: true,
                centered: true,
              })
              .then((value) => {
                if (value) {
                  this.loading = true;

                  axiosIns
                    .delete(`deleteProjectFile/${val.data.id}`)
                    .then((res) => {
                      this.foldersData = res.data;
                      this.totalRows = res.data.length;

                      this.$toast({
                        component: ToastificationContent,
                        props: {
                          title: 'Successful',
                          text: '✔️ File has been deleted',
                          icon: 'ThumbsUpIcon',
                          variant: 'success',
                        },
                      });
                      this.loading = false;
                    })
                    .catch((error) => {
                      this.loading = false;
                    });
                }
              })
              .catch((error) => {
                this.loading = false;
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'An error occurred',
                    text: 'Please try again later or contact support.',
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                });
              });
          } else {
            this.$bvModal
              .msgBoxConfirm('Are you sure you want to delete this folder?', {
                size: 'sm',
                title: 'Confirm Deletion',
                okVariant: 'primary',
                headerBgVariant: 'primary',
                okTitle: 'Yes',
                cancelTitle: 'No',
                cancelVariant: 'outline-secondary',
                hideHeaderClose: true,
                centered: true,
              })
              .then((value) => {
                if (value) {
                  this.loading = true;

                  axiosIns
                    .delete(`deleteProjectFolder/${val.data.id}`)
                    .then((res) => {
                      this.foldersData = res.data;

                      this.$toast({
                        component: ToastificationContent,
                        props: {
                          title: 'Successful',
                          text: '✔️ Folder has been deleted',
                          icon: 'ThumbsUpIcon',
                          variant: 'success',
                        },
                      });

                      this.loading = false;
                    })
                    .catch((error) => {
                      this.loading = false;
                      this.$toast({
                        component: ToastificationContent,
                        props: {
                          title: 'An error occurred',
                          text: 'Please try again later or contact support.',
                          icon: 'AlertTriangleIcon',
                          variant: 'danger',
                        },
                      });
                    });
                }
              })
              .catch((error) => {
                this.loading = false;
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'An error occurred',
                    text: 'Please try again later or contact support.',
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                });
              });
          }

          break;

        default:
          break;
      }
    },

    itemClick(val) {
      if (val.type == 'folder') {
        this.folderTemp.forEach((element) => {
          element.active = false;
        });
        this.folderTemp.push({
          id: val.id,
          text: val.folderName,
          active: true,
          companyId: val.companyId,
        });

        this.topMenuId = val.id;
        this.folderId = val.id;
      } else if (val.type == 'file') {
        this.directoryPath = '';
        this.folderTemp.forEach((element) => {
          this.directoryPath += element.text + '/';
        });

        axiosIns
          .post('downloadProjectFile', { link: val.fileLink }, { responseType: 'blob' })
          .then((res) => {
            const url = window.URL.createObjectURL(new Blob([res.data]));

            const link = document.createElement('a');
            link.href = url;

            link.setAttribute('download', val.fileLink);

            document.body.appendChild(link);
            link.click();
            this.loading = false;
          })
          .catch((error) => {
            this.loading = false;
          });
      }
    },

    handleFolderOk(bvModalEvent) {
      // Prevent modal from closing
      bvModalEvent.preventDefault();

      this.$refs.folderRules.validate().then((success) => {
        if (success) {
          var tempData = {
            folderName: this.modalFolderName,
            topMenuId: this.folderId,
            projectId: this.projectId,
            status: 'A',
          };

          this.loading = true;

          store
            .dispatch('project/saveProjectFolder', tempData)
            .then((res) => {
              this.foldersData = res.data;

              this.$bvModal.hide('modal-new-folder');
              this.loading = false;

              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Successful',
                  text: '✔️ Folder has been created',
                  icon: 'ThumbsUpIcon',
                  variant: 'success',
                },
              });
            })
            .catch((error) => {
              this.loading = false;
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'An error occurred',
                  text: 'Please try again later or contact support.',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              });
            });
        }
      });
    },

    deleteFile(val){


      if (val.type == 'file') {
            this.$bvModal
              .msgBoxConfirm('Are you sure you want to delete this file?', {
                size: 'sm',
                title: 'Confirm Deletion',
                okVariant: 'primary',
                headerBgVariant: 'primary',
                okTitle: 'Yes',
                cancelTitle: 'No',
                cancelVariant: 'outline-secondary',
                hideHeaderClose: true,
                centered: true,
              })
              .then((value) => {
                if (value) {
                  this.loading = true;

                  axiosIns
                    .delete(`deleteProjectFile/${val.id}`)
                    .then((res) => {
                      this.foldersData = res.data;
                      this.totalRows = res.data.length;

                      this.$toast({
                        component: ToastificationContent,
                        props: {
                          title: 'Successful',
                          text: '✔️ File has been deleted',
                          icon: 'ThumbsUpIcon',
                          variant: 'success',
                        },
                      });
                      this.loading = false;
                    })
                    .catch((error) => {
                      console.log('error delete ', error);
                      this.loading = false;
                    });
                }
              })
              .catch((error) => {
                this.loading = false;
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'An error occurred',
                    text: 'Please try again later or contact support.',
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                });
              });
          } else {
            this.$bvModal
              .msgBoxConfirm('Are you sure you want to delete this folder?', {
                size: 'sm',
                title: 'Confirm Deletion',
                okVariant: 'primary',
                headerBgVariant: 'primary',
                okTitle: 'Yes',
                cancelTitle: 'No',
                cancelVariant: 'outline-secondary',
                hideHeaderClose: true,
                centered: true,
              })
              .then((value) => {
                if (value) {
                  this.loading = true;

                  axiosIns
                    .delete(`deleteProjectFolder/${val.id}`)
                    .then((res) => {
                      this.foldersData = res.data;

                      this.$toast({
                        component: ToastificationContent,
                        props: {
                          title: 'Successful',
                          text: '✔️ Folder has been deleted',
                          icon: 'ThumbsUpIcon',
                          variant: 'success',
                        },
                      });

                      this.loading = false;
                    })
                    .catch((error) => {
                      this.loading = false;
                      this.$toast({
                        component: ToastificationContent,
                        props: {
                          title: 'An error occurred',
                          text: 'Please try again later or contact support.',
                          icon: 'AlertTriangleIcon',
                          variant: 'danger',
                        },
                      });
                    });
                }
              })
              .catch((error) => {
                this.loading = false;
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'An error occurred',
                    text: 'Please try again later or contact support.',
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                });
              });
          }

    },

    handleFolderUpdate(bvModalEvent) {
      // Prevent modal from closing

      bvModalEvent.preventDefault();

      var data = {
        id: this.selectFolderId,
        folderName: this.modalFolderName,
        topMenuId: this.folderId,
        status: 'A',
      };
      // Trigger submit handler

      this.modalFolderUpdate(data);
    },

    handleFileUpdate(bvModalEvent) {
      // Prevent modal from closing
      bvModalEvent.preventDefault();
      var data = {
        id: this.selectFileId,
        fileName: this.modalFileName,
        status: 'A',
      };
      // Trigger submit handler

      this.modalFileUpdate(data);
    },

    handleFileOk(bvModalEvent) {
      bvModalEvent.preventDefault();

      this.$refs.fileRules.validate().then((success) => {
        if (success) {
          if (this.percentCompleted == 0) {
            this.directoryPath = '';
            this.folderTemp.forEach((element) => {
              this.directoryPath += element.text + '/';
            });
            this.loading = true;
            this.uploadFilesSequentially(0);
          }
        }
      });
    },

    modalFileOk(tempData) {
      store
        .dispatch('project/saveProjectFile', tempData)
        .then((res) => {
          this.foldersData = res.data;
          this.modalFile = [];
          this.modalFolderName= null;
          this.modalFiles= null;
          this.percentCompleted= 0;

          this.$bvModal.hide('modal-new-file');
          this.loading = false;

          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Successful',
              text: '✔️ File has been uploaded',
              icon: 'ThumbsUpIcon',
              variant: 'success',
            },
          });
        })
        .catch((error) => {
          this.loading = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'An error occurred',
              text: 'Please try again later or contact support.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          });
        });
    },

    uploadFilesSequentially(index) {
      if (index < this.modalFiles.length) {
        let element = this.modalFiles[index];
        let formData = new FormData();

        formData.append('file', element);
        formData.append('path', this.directoryPath);

        const config = {
          onUploadProgress: function(progressEvent) {
            this.percentCompleted = Math.round((progressEvent.loaded * (100 - (this.modalFiles.length + 1))) / progressEvent.total);
          }.bind(this),
        };

        axiosIns
          .post('uploadProjectFile', formData, config)
          .then((response) => {
            this.modalFile.push(response.data);
            if (index === this.modalFiles.length - 1) {
              this.percentCompleted = 100;
            }

            if (this.percentCompleted === 100) {
              var data = {
                topMenuId: this.folderId,
                fileDetails: {
                  fileName: this.modalFile,
                  fileLink: this.directoryPath,
                },

                projectId: this.projectId,
                status: 'A',
              };

              this.modalFileOk(data);
            }

            this.uploadFilesSequentially(index + 1);
          })
          .catch((error) => {
            this.loading = false;
          });
      }
    },

    modalFolderUpdate(data) {
      this.$nextTick(() => {
        this.$bvModal.hide('modal-edit-folder');
      });

      this.loading = true;

      store
        .dispatch('project/updateProjectFolder', data)
        .then((res) => {
          this.foldersData = res.data;
          this.modalFolderName = null;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
        });
    },

    modalFileUpdate(data) {
      this.$nextTick(() => {
        this.$bvModal.hide('modal-edit-file');
      });

      this.loading = true;

      store
        .dispatch('project/updateProjectFile', data)
        .then((res) => {
          this.foldersData = res.data;
          this.modalFileName = null;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
        });
    },

    resetModal() {
      this.modalFolderName = null;
      this.modalFiles = null;
    },

    fileSelected(event) {
      this.directoryPath = '';
      this.folderTemp.forEach((element) => {
        this.directoryPath += element.text + '/';
      });

      var total = event.length;
      event.forEach((element, i) => {
        let formData = new FormData();

        formData.append('file', element);
        formData.append('path', this.directoryPath);

        const config = {
          onUploadProgress: function(progressEvent) {
            this.percentCompleted = Math.round((progressEvent.loaded * (100 - (event.length + 1))) / progressEvent.total);
          }.bind(this),
        };

        axiosIns
          .post('uploadProjectFile', formData, config)
          .then((response) => {
            this.modalFile.push(response.data);

            total = total - 1;

            if (total == 0) {
              this.percentCompleted = 100;
            }
            this.loading = false;
          })
          .catch((error) => {
            this.loading = false;
          });
      });
    },

    
    dateFormat(val) {
      if (val != null) {
        moment.locale("en-US");
        return moment(val, "YYYY-MM-DD").format("DD/MM/YYYY");
      } else {
        return "N/A";
      }
    },







    clearSidebarData() {
      this.$emit('update:is-project-sidebar-active', false);
      this.$emit('update:project-modal-id', null);
      if (store.hasModule(PROJECT_APP_STORE_MODULE_NAME)) store.unregisterModule(PROJECT_APP_STORE_MODULE_NAME);
    },

    truncateText(text, maxLength) {
      if (text.length > maxLength) {
        return text.substring(0, maxLength) + '...';
      } else {
        return text;
      }
    },

    variantTimeline(val) {
      if (val == 'Approved') {
        return 'success';
      } else if (val == 'Rejected') {
        return 'danger';
      } else if (val == 'Call') {
        return 'warning';
      } else if (val == 'Edited' || val == 'QuoteEdited') {
        return 'secondary';
      } else if (val == 'Canceled') {
        return 'danger';
      } else if (val == 'Completed') {
        return 'success';
      } else if (val == 'Created' || val == 'QuoteCreated') {
        return 'secondary';
      } else if (val == 'Processed') {
        return 'primary';
      } else if (val == 'ChargeString') {
        return 'success';
      } else if (val == 'Survey') {
        return 'warning';
      } else if (val == 'Other') {
        return 'secondary';
      } else if (val == 'Mailed' || val == 'MailSent') {
        return 'warning';
      } else if (val == 'Archived') {
        return 'primary';
      } else if (val == 'ChargeReminder') {
        return 'warning';
      } else if (val == 'LastChargeReminder') {
        return 'danger';
      } else if (val == 'Certificate') {
        return 'secondary';
      } 
      else if (val == 'ContactEdit') {
        return 'warning';
      }
      else if (val == 'ChargeStringEdit') {
        return 'secondary';
      }
      
      else {
        return 'success';
      }
    },

    iconTimeline(val) {
      if (val == 'Approved') {
        return 'ThumbsUpIcon';
      } else if (val == 'Rejected') {
        return 'XIcon';
      } else if (val == 'Call') {
        return 'PhoneIcon';
      } else if (val == 'Edited' || val == 'QuoteEdited') {
        return 'EditIcon';
      } else if (val == 'Canceled') {
        return 'Trash2Icon';
      } else if (val == 'Completed') {
        return 'CheckIcon';
      } else if (val == 'Created' || val == 'QuoteCreated') {
        return 'FlagIcon';
      } else if (val == 'Processed') {
        return 'ShoppingCartIcon';
      } else if (val == 'ChargeString') {
        return 'DollarSignIcon';
      } else if (val == 'Survey') {
        return 'SmileIcon';
      } else if (val == 'Other') {
        return 'BellIcon';
      } else if (val == 'Mailed' || val == 'MailSent') {
        return 'MailIcon';
      } else if (val == 'Archived') {
        return 'ArchiveIcon';
      } else if (val == 'ChargeReminder') {
        return 'ClockIcon';
      } else if (val == 'LastChargeReminder') {
        return 'AlertCircleIcon';
      } else if (val == 'Certificate') {
        return 'FileTextIcon';
      }
      else if (val == 'Invoiced') {
        return 'DollarSignIcon';
      }
      else if (val == 'ContactEdit') {
        return 'EditIcon';
      }
      else if (val == 'ChargeStringEdit') {
        return 'EditIcon';
      }
      
      else {
        return 'success';
      }
    },

    dateFormatTimeline(val) {
      return moment(String(val)).format('YYYY-MM-DD HH:mm');
    },
  },
};
</script>

<style lang="scss" scoped>
span.activeMenu {
  font-weight: 700;
  color: black;
  padding-bottom: 2px;
  box-shadow: inset 0 -2px #6d6e6f;
  transition: padding 0.3s;
  padding: 5px 10px 5px;
  margin-right: 10px;
}

span.passiveMenu {
  font-weight: 400;
  color: #6d6e6f;
  padding-bottom: 2px;
  transition: padding 0.3s;
  padding: 5px 10px 5px;
  margin-right: 10px;
}

span.activeMenu:hover {
  background-color: #f9f8f8;
  padding: 5px 10px 5px;
  cursor: pointer;
  transition: padding 0.3s;
}

span.passiveMenu:hover {
  background-color: #f9f8f8;
  box-shadow: inset 0 -2px #afabac;
  padding: 5px 10px 5px;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  cursor: pointer;
  transition: padding 0.3s;
}

.cardClass {
  padding: 1.25rem;
  margin-bottom: 15%;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '~vue-context/dist/css/vue-context.css';
</style>
./projectStoreModule
