<template>
  <b-modal id="compose-mail" :visible="shallShowEmailComposeModal" title="Compose Email" modal-class="modal-sticky" footer-class="d-flex justify-content-between" body-class="p-3" size="lg" no-fade hide-backdrop static>
    <!-- Modal Header -->
    <template #modal-header>
      <h5 class="modal-title">
        Compose Mail
      </h5>
      <div class="modal-actions">
        <feather-icon icon="XIcon" class="ml-1 cursor-pointer" @click="discardEmail" />
      </div>
    </template>

    <!-- Modal Footer -->
    <template #modal-footer>
      <!-- Footer: Left Content -->
      <div>
        <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" @click="sendEmail">
          Send
        </b-button>
      </div>

      <div></div>
    </template>

    <!-- Modal: Body -->
    <b-form>
      <!-- Field: To -->
      <b-row class="mb-1">
        <b-col cols="12" sm="12" md="2">
          <label for="email-to" class="form-label" style="font-size: smaller; width: 15%;">To: </label>
        </b-col>
        <b-col cols="12" sm="12" md="10">
          <b-form-input disabled id="email-subject" v-model="composeData.to" />
        </b-col>
      </b-row>

      <b-row class="mb-1">
        <b-col cols="12" sm="12" md="2">
          <label for="email-to" class="form-label" style="font-size: smaller; width: 15%;">Subject: </label>
        </b-col>
        <b-col cols="12" sm="12" md="10">
          <b-form-input id="email-subject" v-model="composeData.subject" />
        </b-col>
      </b-row>

      <!-- Field: Message - Quill Editor -->
      <div class="message-editor">
        <quill-editor id="quil-content" v-model="composeData.message" :options="editorOption" style="height: 150px;" />
        <div id="quill-toolbar" class="d-flex border-bottom-0">
          <!-- Add a bold button -->
          <button class="ql-bold" />
          <button class="ql-italic" />
          <button class="ql-underline" />
          <button class="ql-align" />
          <button class="ql-link" />
        </div>
      </div>
    </b-form>
  </b-modal>
</template>

<script>
import { BDropdown, BDropdownItem, BForm, BFormInput, BAvatar, BDropdownDivider } from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive';
import { ref } from '@vue/composition-api';
import { quillEditor } from 'vue-quill-editor';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import vSelect from 'vue-select';
import store from '@/store';

export default {
  directives: {
    Ripple,
  },
  components: {
    // BSV
    BDropdown,
    BDropdownItem,
    BForm,
    BFormInput,
    BAvatar,
    BDropdownDivider,

    // 3rd Party
    quillEditor,
    vSelect,
  },
  data() {
    return {
      composeData: { to: null, subject: null, message: null, projectId: null },

      editorOption: {
        modules: {
          toolbar: '#quill-toolbar',
        },
        placeholder: 'Message',
      },
    };
  },

  created() {
    this.composeData.to = this.sendEmailTo;
    this.composeData.projectId = this.projectId;
  },

  model: {
    prop: 'shallShowEmailComposeModal',
    event: 'update:shall-show-email-compose-modal',
  },
  props: {
    shallShowEmailComposeModal: {
      type: Boolean,
      required: true,
    },
    sendEmailTo: {
      type: String,
      required: true,
    },
    projectId: {
      type: Number,
      required: true,
    },
  },

  methods: {
    discardEmail() {
      (this.composeData = { to: null, subject: null, message: null, projectId: null }), this.$emit('update:shall-show-email-compose-modal', false);
      this.$emit('update:mail-project-id', null);
      this.$emit('update:send-mail-to', null);
    },

    sendEmail() {
      store
        .dispatch('project/sendMail', this.composeData)
        .then((response) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Successful',
              text: '✔️Email has been sent',
              icon: 'ThumbsUpIcon',
              variant: 'success',
            },
          });
          this.loading = false;
        })
        .catch((error) => {
          this.formShow = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'An error occurred',
              text: 'Please try again later or contact support.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          });
        });

      (this.composeData = { to: null, subject: null, message: null, projectId: null }), this.$emit('update:mail-project-id', null);
      this.$emit('update:send-mail-to', null);
      this.$emit('update:shall-show-email-compose-modal', false);
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/quill.scss';
</style>

<style lang="scss" scoped>
form ::v-deep {
  // Mail To vue-select style
  .v-select {
    .vs__dropdown-toggle {
      border: 0;
      box-shadow: none;
    }
    .vs__open-indicator {
      display: none;
    }
  }

  // Quill Editor Style
  .quill-editor {
    .ql-container.ql-snow {
      border-bottom: 0 !important;
    }
  }
}
</style>
