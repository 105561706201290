<template>
  <div class="mt-1">
    <b-card>
      <b-overlay :show="loading" rounded="sm" no-fade>
        <b-row class="mb-1">
          <b-col cols="12" md="3" class="d-flex align-items-center justify-content-start   mb-md-0">
            <label>Show</label>
            <v-select v-model="perPage" :options="perPageOptions" :clearable="false" class="per-page-selector d-inline-block mx-1" />
            <label>entries </label>
          </b-col>

          <b-col cols="12" md="2" class="d-flex align-items-center justify-content-start   mb-md-0">
            <treeselect v-model="selectedProcess" :multiple="false" :options="processOptions" placeholder="Process Filter" />
          </b-col>

          <b-col cols="12" md="3">
            <treeselect v-model="selectedLab" :multiple="true" :options="labs" :limit="1" placeholder="All Labs" />
          </b-col>

          <b-col cols="12" md="4">
            <div class="d-flex align-items-center justify-content-end">
              <b-button :style="{ backgroundColor: !selectModeData && ids.length > 0 ? '#2ecc71!important' : '#3498db!important' }" style="border:none" @click="selectMode">
                <span class="text-nowrap">
                  <i v-if="ids.length === 0" :class="[selectModeData ? 'fa-eye-slash' : 'fa-eye', 'fas fa-sm']"></i>

                  {{ !selectModeData && ids.length > 0 ? 'Send to Archive' : 'Archive Mode' }}</span
                >
              </b-button>
              <b-button variant="primary" class="ml-1" router-link to="/project/project-add">
                <span class="text-nowrap">Add Project</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

        <b-row class="mb-1">
          <b-col cols="12" md="3">
            <flat-pickr placeholder="Select Date Range" id="dateGenerated" multiple v-model="dateRange" class="form-control" :config="{ mode: 'range', dateFormat: 'Y-m-d', locale: { firstDayOfWeek: 1 } }" style="background-color: white;" />
          </b-col>

          <b-col cols="12" md="2">
            <treeselect v-model="type" :multiple="false" :options="formTypeOptions" placeholder="All Projects" />
          </b-col>

          <b-col cols="12" md="3">
            <treeselect v-model="selectedDepartment" :multiple="true" :options="departments" :limit="1" placeholder="All Departments" />
          </b-col>

          <b-col cols="12" md="4">
            <b-form-input v-model="searchQuery" class="d-inline-block" cle placeholder="Search..." />
          </b-col>
        </b-row>

        <b-table
          hover
          ref="refFormListTable"
          class="position-relative"
          @row-clicked="onRowSelected"
          :items="fetchForms"
          :outlined="true"
          :selectable="selectModeData"
          select-mode="single"
          responsive
          :small="true"
          :fields="tableColumns"
          show-empty
          empty-text="No matching records found"
          :per-page="perPage"
        >
          <!-- Column: selection -->
          <template #cell(selection)="data" v-if="!selectModeData">
            <b-form-checkbox v-model="data.item.selected" @change="onCheckboxChange" />
          </template>
          <template #cell(selection)="data" v-else>
            <div class="custom-checkbox text-center mr-1 ml-1">
            <span class="checkbox-icon" :class="{ checked: data.item.invoiceApproved }"></span>
          </div>

          </template>

          <!-- Column: Id -->
          <template #cell(id)="data">
            <strong class="text-primary">#{{ data.value }}</strong>
          </template>

          <!-- Column: contact -->
          <template #cell(contact)="data">
            {{ data.value }}
          </template>

          <!-- Column: contactMail -->
          <template #cell(created_at)="data">
            {{ formatDate(data.value) }}
          </template>

          <!-- Column: partName -->
          <template #cell(projectName)="data">
            {{ data.value }}
          </template>

          <template #cell(process)="data">
            <b-badge v-if="data.value == 'draft'" pill variant="light-secondary" class="text-capitalize">
              {{ data.value }}
            </b-badge>

            <b-badge v-if="data.value == 'shop'" pill variant="primary" class="text-capitalize">
              Processing
            </b-badge>

            <b-badge v-if="data.value == 'ponumber'" pill variant="light-primary" class="text-capitalize">
              Awaiting PO
            </b-badge>

            <b-badge v-if="data.value == 'certification'" pill variant="light-warning" class="text-capitalize">
              Certification
            </b-badge>

            <b-badge v-if="data.value == 'completed'" pill variant="success" class="text-capitalize">
              Completed
            </b-badge>

            <b-badge v-if="data.value == 'quote' && data.item.form_quote.customerResponse == 'draft'" pill variant="secondary" class="text-capitalize"> {{ data.value }} Draft </b-badge>

            <b-badge v-if="data.value == 'quote' && data.item.form_quote.customerResponse == 'approve'" pill variant="success" class="text-capitalize"> {{ data.value }} Approved </b-badge>

            <b-badge v-if="data.value == 'quote' && data.item.form_quote.customerResponse == 'reject'" pill variant="danger" class="text-capitalize"> {{ data.value }} Rejected </b-badge>

            <b-badge v-if="data.value == 'quote' && data.item.form_quote.customerResponse == 'call'" pill variant="light-danger" class="text-capitalize"> {{ data.value }} Call Req. </b-badge>

            <b-badge v-if="data.value == 'quote' && data.item.form_quote.customerResponse == 'mailed'" pill variant="warning" class="text-capitalize"> {{ data.value }} Emailed </b-badge>
          </template>

          <template #cell(digCode)="data">
            <span style="font-size: 1rem; white-space: pre-line;">
              {{ data.value ? data.value.match(/.{1,17}/g).join('\n') : 'N/A' }}
            </span>
          </template>

          <!-- Column: Type -->
          <template #cell(type)="data">
            <!-- <b-badge v-if="data.value == 'internal'" pill :variant="`light-danger`" class="text-capitalize">
              {{ data.value }}
            </b-badge>

            <b-badge v-if="data.value == 'external'" pill :variant="`light-success`" class="text-capitalize">
              {{ data.value }}
            </b-badge> -->

            <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret>
              <template v-slot:button-content>
                <b-badge v-if="data.value == 'internal'" pill :variant="`light-success`" class="text-capitalize"> I <feather-icon style="margin-bottom:2px !important;" icon="ChevronDownIcon" /> </b-badge>

                <b-badge v-if="data.value == 'external'" pill :variant="`light-danger`" class="text-capitalize"> E <feather-icon style="margin-bottom:2px  !important;" icon="ChevronDownIcon" /> </b-badge>
              </template>
              <b-dropdown-item :disabled="data.value == 'internal'" @click="typeChange('internal', data)">
                <feather-icon icon="Minimize2Icon" class="mr-50" />
                <span>Internal </span>
              </b-dropdown-item>
              <b-dropdown-item :disabled="data.value == 'external'" @click="typeChange('external', data)">
                <feather-icon icon="ExternalLinkIcon" class="mr-50" />
                <span>External</span>
              </b-dropdown-item>
            </b-dropdown>
          </template>

          <template #cell(actions)="data">
            <div class="text-nowrap">
              <!-- <feather-icon v-show="$Can('invoice_send')" :id="`invoice-row-${data.item.id}-send-icon`" icon="SendIcon" class="cursor-pointer" size="16" />
            <b-tooltip title="Send Invoice" class="cursor-pointer" :target="`invoice-row-${data.item.id}-send-icon`" /> -->

              <!-- <feather-icon v-show="$Can('invoice_preview')" :id="`invoice-row-${data.item.id}-preview-icon`" icon="EyeIcon" size="16" class="mx-1" @click="$router.push({ name: 'invoice-preview', params: { id: data.item.id } })" />
            <b-tooltip title="Preview Invoice" :target="`invoice-row-${data.item.id}-preview-icon`" /> -->

              <!-- Dropdown -->
              <b-dropdown variant="link" toggle-class="p-0" no-caret :right="$store.state.appConfig.isRTL">
                <template #button-content>
                  <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
                </template>
                <b-dropdown-item @click="openHistory(data.item)">
                  <feather-icon icon="MenuIcon" />
                  <span class="align-middle ml-50">History</span>
                </b-dropdown-item>
                <b-dropdown-item :href="'https://orderform.harvard.systemstailormade.com/trackMyRequest.php?trackNumber=' + data.item.trackNumber" target="_blank">
                  <feather-icon icon="MousePointerIcon" />
                  <span class="align-middle ml-50">Track</span>
                </b-dropdown-item>
                <!-- 30-05 COMMENT -->
                <!-- <b-dropdown-item @click="openEmail(data.item)">
                  <feather-icon icon="MailIcon" />
                  <span class="align-middle ml-50">Email</span>
                </b-dropdown-item> -->
                <b-dropdown-item @click="junkQuote(data.item.id)">
                  <feather-icon icon="ArchiveIcon" />
                  <span class="align-middle ml-50">Archive</span>
                </b-dropdown-item>

                <b-dropdown-item v-if="data.item.invoiceApproved" @click="viewInvoice(data.item.invoiceId)">
                  <feather-icon icon="DollarSignIcon" />
                  <span class="align-middle ml-50">View Invoice</span>
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </template>

          <!-- Column: Status -->
          <!-- <template #cell(status)="data">
            <b-badge v-if="data.value == 'active'" pill :variant="`light-success`" class="text-capitalize">
              Active
            </b-badge>

            <b-badge v-if="data.value == 'passive'" pill :variant="`light-danger`" class="text-capitalize">
              Passive
            </b-badge>
          </template> -->
        </b-table>

        <div class="mx-2 mb-2">
          <b-row>
            <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
              <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
            </b-col>

            <!-- Pagination -->
            <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
              <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" first-number last-number class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item">
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>

        <div class="text-right" v-if="junkRecordsVisible == false">
          <b-button variant="secondary" size="sm" @click="showJunkRecords('passive')">Show archive records</b-button>
        </div>

        <div class="text-right" v-else>
          <b-button variant="primary" size="sm" @click="showJunkRecords('active')">Show active records</b-button>
        </div>
      </b-overlay>
    </b-card>
    <email-compose v-if="shallShowEmailComposeModal" v-model="shallShowEmailComposeModal" :send-email-to="sendEmailTo" :project-id="mailFormId"> </email-compose>
    <history-sidebar v-if="isHistorySidebarActive" :is-project-sidebar-active="isHistorySidebarActive" :project-id="historyFormId" v-model="isHistorySidebarActive" />
  </div>
</template>

<script>
import { BOverlay, BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink, BBadge, BDropdown, BDropdownItem, BPagination, BFormRating } from 'bootstrap-vue';
import store from '@/store';
import { onUnmounted } from '@vue/composition-api';
import projectStoreModule from './projectStoreModule';
import vSelect from 'vue-select';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import router from '@/router';
import { formatDateTime, formatDate } from '../helpers';
import Treeselect from '@riophae/vue-treeselect';
import EmailCompose from './EmailCompose.vue';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
import HistorySidebar from './HistorySidebar.vue';
import flatPickr from 'vue-flatpickr-component';

export default {
  components: {
    BOverlay,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormRating,
    vSelect,
    ToastificationContent,
    Treeselect,
    EmailCompose,
    HistorySidebar,
    flatPickr,
  },

  setup() {
    const FORM_APP_STORE_MODULE_NAME = 'project';
    // Register module
    if (!store.hasModule(FORM_APP_STORE_MODULE_NAME)) store.registerModule(FORM_APP_STORE_MODULE_NAME, projectStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(FORM_APP_STORE_MODULE_NAME)) store.unregisterModule(FORM_APP_STORE_MODULE_NAME);
    });
  },

  data() {
    return {
      ids: [],
      selectModeData: true,
      checkedRows: [],
      selected: [],
      dateRange: [],
      fetchForms: [],
      labs: [],
      departments: [],
      junkRecordsVisible: false,
      tableColumns: [
        { key: 'selection', label: '', sortable: false, class: 'text-center', thStyle: 'width:5%' },
        { key: 'id', label: 'JOB#', sortable: false, class: 'text-center', thStyle: 'width:5%' },
        { key: 'type', label: 'I/E', sortable: false, class: 'text-center', thStyle: 'width:5%' },
        { key: 'contact', sortable: false, class: 'text-center', thStyle: 'width:10%' },
        { key: 'created_at', label: 'Date', sortable: false, class: 'text-center', thStyle: 'width:15%' },
        { key: 'department', label: 'Department', sortable: false, class: 'text-center', thStyle: 'width:10%' },
        { key: 'lab', label: 'Lab', sortable: false, class: 'text-center', thStyle: 'width:10%' },
        { key: 'projectName', label: 'Project Name', sortable: false, class: 'text-center', thStyle: 'width:10%' },
        { key: 'digCode', label: 'Charge String/PO#', sortable: false, class: 'text-center', thStyle: 'width:15%' },
        { key: 'process', sortable: false, class: 'text-center', thStyle: 'width:10%' },
        { key: 'actions', sortable: false, class: 'text-center', thStyle: 'width:5%' },
      ],
      perPage: parseInt(localStorage.getItem('formShow')) || 10,
      currentPage: 1,
      totalRows: 0,
      perPageOptions: [10, 25, 50, 100],
      loading: true,
      searchQuery: '',
      selectedLab: [],
      selectedDepartment: [],
      type: null,
      formTypeOptions: [
        { id: 'internal', label: 'Internal Projects' },
        { id: 'external', label: 'External Projects' },
      ],
      processOptions: [
        { id: 'draft', label: 'Draft' },
        { id: 'shop', label: 'Processing' },
        { id: 'ponumber', label: 'Awaiting PO' },
        { id: 'certification', label: 'Certification' },
        { id: 'completed', label: 'Completed' },

        { id: 'quote_draft', label: 'Quote Draft' },
        { id: 'quote_approved', label: 'Quote Approved' },
        { id: 'quote_rejected', label: 'Quote Rejected' },
        { id: 'quote_call_req', label: 'Quote Call Request' },
        { id: 'quote_emailed', label: 'Quote Emailed' },
      ],
      selectedProcess: null,
      shallShowEmailComposeModal: false,
      sendEmailTo: null,
      mailFormId: null,
      isHistorySidebarActive: false,
      historyFormId: null,
    };
  },

  watch: {
    perPage: 'debouncedRefetchFilter',
    type: {
      handler: 'formFilter',
      immediate: false,
    },
    selectedLab: 'formFilter',
    selectedProcess: 'formFilter',
    selectedDepartment: 'formFilter',
    currentPage: 'debouncedRefetchFilter',
    searchQuery: 'debouncedFilter',
    dateRange: {
      handler(newValue, oldValue) {
        if (!Array.isArray(newValue) && newValue && newValue.includes('to')) {
          this.formFilter();
        } else if (Array.isArray(newValue)) {
          return;
        }
      },
      immediate: false,
    },
  },

  methods: {
    formatDateTime,
    formatDate,

    selectMode() {
      if (this.selectModeData) {
        this.selectModeData = !this.selectModeData;
      } else {
        this.ids = [];
        this.fetchForms.forEach((element) => {
          if (element.selected == true) {
            this.ids.push(element.id);
          }
        });

        if (this.ids.length > 0) {
          this.loading = true;
          store
            .dispatch('project/selectedJunkForms', { ids: this.ids })
            .then((res) => {
              this.getForms();
            })
            .catch((error) => {
              this.loading = false;
              this.$swal({
                icon: 'error',
                title: 'An Error Occurred',
                text: 'An error occurred. Please try again.',
              });
            });
        }

        this.selectModeData = !this.selectModeData;
      }
    },

    openEmail(item) {
      this.shallShowEmailComposeModal = true;
      this.sendEmailTo = item.contactEmail;
      this.mailFormId = item.id;
    },

    openHistory(item) {
      this.isHistorySidebarActive = true;
      this.historyFormId = item.id;
    },


    
    viewInvoice(item) {
      router.push({ name: 'invoice-preview', params: { id: item } });

    },

    junkQuote(id) {
      this.$bvModal
        .msgBoxConfirm('Are you sure you want to move project to archive?', {
          size: 'sm',
          title: 'Confirm Move to Archive',
          okVariant: 'primary',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: true,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.loading = true;

            store
              .dispatch('project/junkForm', id)
              .then((response) => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Successful',
                    text: '✔️ Project successfully moved to archive',
                    icon: 'ThumbsUpIcon',
                    variant: 'success',
                  },
                });

                this.getForms();
              })
              .catch((error) => {
                this.loading = false;
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'An error occurred',
                    text: 'Please try again later or contact support.',
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                });
              });
          }
        });
    },

    typeChange(val, data) {
      this.$bvModal
        .msgBoxConfirm('Are you sure you want to change the type of this project?', {
          size: 'sm',
          title: 'Job Number : ' + data.item.id,
          okVariant: 'primary',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: true,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.loading = true;

            store
              .dispatch('project/changeFormType', { id: data.item.id, type: val })
              .then((response) => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Successful',
                    text: '✔️ Project type change successful',
                    icon: 'ThumbsUpIcon',
                    variant: 'success',
                  },
                });
                this.loading = false;
                data.item.type = val;
              })
              .catch((error) => {
                this.formShow = false;
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'An error occurred',
                    text: 'Please try again later or contact support.',
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                });
              });
          }
        });
    },

    debouncedRefetchFilter: function() {
      this.loading = true;
      clearTimeout(this.timer);
      localStorage.setItem('formShow', this.perPage);
      let status = this.junkRecordsVisible ? 'passive' : 'active'
      this.timer = setTimeout(() => {
        this.getForms(status);
      }, 200);
    },

    debouncedFilter: function() {
      clearTimeout(this.timer);
      let status = this.junkRecordsVisible ? 'passive' : 'active'

      this.timer = setTimeout(() => {
        this.getForms(status);
      }, 1000);
    },

    formFilter: function() {
      let status = this.junkRecordsVisible ? 'passive' : 'active'

      this.getForms(status);
    },

    onRowSelected(item) {
      if (!this.selectModeData) {
        item.selected = !item.selected;
        this.ids = [];
        this.fetchForms.forEach((element) => {
          if (element.selected == true) {
            this.ids.push(element.id);
          }
        });
      } else {
        router.push({ name: 'project-preview', params: { id: item.id } });
      }
    },

    onCheckboxChange() {
      this.ids = [];
      this.fetchForms.forEach((element) => {
        if (element.selected == true) {
          this.ids.push(element.id);
        }
      });
    },

    showJunkRecords(status) {
      this.junkRecordsVisible = !this.junkRecordsVisible;
      this.getForms(status);
    },

    getForms(status) {
      this.loading = true;
      store
        .dispatch('project/getAllForms', {
          perPage: this.perPage,
          currentPage: this.currentPage,
          searchQuery: this.searchQuery,
          type: this.type,
          selectedLab: this.selectedLab,
          selectedDepartment: this.selectedDepartment,
          status: status,
          selectedProcess: this.selectedProcess,
          dateRange: this.dateRange,
        })
        .then((res) => {
          this.fetchForms = res.data.data.map((element) => ({ ...element, selected: false }));

          this.totalRows = res.data.total;
          this.loading = false;
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'An error occurred',
              text: 'Please try again later or contact support.',
              icon: 'AlertCircleIcon',
              variant: 'danger',
            },
          });

          this.loading = false;
        });
    },

    getDepLabDatas() {
      this.loading = true;
      store
        .dispatch('project/getDepLabData')
        .then((res) => {
          this.labs = res.data.labs.map((item) => ({ id: item, label: item }));
          this.departments = res.data.departments.map((item) => ({ id: item, label: item }));
          this.getForms();
        })
        .catch((error) => {
          console.log(error);
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'An error occurred',
              text: 'Please try again later or contact support.',
              icon: 'AlertCircleIcon',
              variant: 'danger',
            },
          });

          this.loading = false;
        });
    },
  },

  created() {
    this.getDepLabDatas();
  },

  computed: {
    dataMeta() {
      return {
        from: this.totalRows != 0 ? this.perPage * (this.currentPage - 1) + 1 : 0,
        to: this.perPage * (this.currentPage - 1) + Number(this.perPage) < this.totalRows ? this.perPage * (this.currentPage - 1) + Number(this.perPage) : this.totalRows,
        of: this.totalRows,
      };
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.form-type-selector {
  width: 100%;
}

.toggle-link {
  cursor: pointer;
  color: #a51c30;
  text-decoration: underline;
}

.custom-checkbox {
  position: relative;
}

/* Style the custom checkbox container */
.custom-checkbox .checkbox-icon {
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
/* Style the unchecked state of the checkbox icon */
.custom-checkbox .checkbox-icon:not(.checked)::before {

  display: none;
}

/* Style the checked state of the checkbox icon */
.custom-checkbox .checkbox-icon.checked {
  background-color: #fff; /* Green background when checked */
  color: #03882bcc;
  border: 1px solid #03882bcc;
  border-radius: 50%;


}

/* Style the tick icon (a simple checkmark) when checked */
.custom-checkbox .checkbox-icon.checked::before {
  content: '\2713'; /* Unicode checkmark character */
  font-size: 25px;
  color: #03882bcc;
}

</style>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-select.scss';
@import '~@core/scss/base/pages/app-email.scss';
</style>
./projectStoreModule
