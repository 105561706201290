// helpers.js

export function formatDateTime(dateTimeString) {
  const dateTime = new Date(dateTimeString);
  const year = dateTime.getFullYear();
  const month = String(dateTime.getMonth() + 1).padStart(2, '0');
  const day = String(dateTime.getDate()).padStart(2, '0');
  const hour = String(dateTime.getHours()).padStart(2, '0');
  const minute = String(dateTime.getMinutes()).padStart(2, '0');

  return `${year}-${month}-${day} ${hour}:${minute}`;
}

export function formatDate(value) {
  if (!value) return value;
  const date = new Date(value);
  const monthIndex = date.getMonth() + 1;
  const month = new Intl.DateTimeFormat('en-US', { month: 'short' }).format(date);
  const day = date
    .getDate()
    .toString()
    .padStart(2, '0');
  const year = date.getFullYear().toString();

  return `${year}-${monthIndex}-${day}`;
}
export function hoursFormat(val) {
  var totalMinutes = Math.floor(val / 60);

  var hours = Math.floor(totalMinutes / 60);
  var minutes = totalMinutes % 60;

  return hours + ' Hours, ' + minutes + ' Minutes';
}

export function getTodayDate() {
  const today = new Date();
  const day = String(today.getDate()).padStart(2, '0');
  const month = String(today.getMonth() + 1).padStart(2, '0'); // Note: January is 0!
  const year = today.getFullYear();
  const todayDate = `${year}-${month}-${day}`;
  return todayDate;
}
